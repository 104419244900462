
/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification\ 
for details on configuring this project to bundle and minify static web assets. */
body {
    padding-top: 50px;
    padding-bottom: 20px;
}

/* Wrapping element */
/* Set some basic padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* Carousel */
.carousel-caption p {
    font-size: 20px;
    line-height: 1.4;
}

/* Make .svg files in the carousel display properly in older browsers */
.carousel-inner .item img[src$=".svg"] {
    width: 100%;
}

/* QR code generator */
#qrCode {
    margin: 15px;
}

/* Hide/rearrange for smaller screens */
@media screen and (max-width: 767px) {
    /* Hide captions */
    .carousel-caption {
        display: none;
    }
}

/* para ajustar el color de los botones success*/
.btn.btn-success {
    color: #ffffff;
    background-color: var(--color-gizmedic) !important;
    border-color: var(--color-gizmedic) !important;
}

/* seteo el color de gizmedic para acceder desde cualquier page */
:root {
    --color-gizmedic: #0D9FCA;
    --color-gizmedic-inactive: #76b4c7;
    --color-gizmedic-active: #13c9ff;
}

.btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-hover-primary:focus:not(.btn-text), .btn.btn-hover-primary.focus:not(.btn-text) {
    color: #FFFFFF !important;
    background-color: #d8eaef !important;
    border-color: #d8eaef !important;
}

.header-fixed.subheader-fixed.subheader-enabled .wrapper {
    padding-top: 10px !important;
}


/* Estilos para angular*/

.ui-select-container {
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #E4E6EF;
    border-radius: 0.42rem;
    box-shadow: none;
}

.ui-select-container > a {
    height: inherit !important;
    padding-top: 5px !important;
}

span.select2-arrow {
    padding-top: inherit !important
}

div.tableFixHead {
    overflow-y: auto;
}

div.tableFixHead-traslados {
}



    div.tableFixHead thead th {
        position: sticky;
        top: 0;
        z-index: 9;
    }

    div.tableFixHead table {
        border-collapse: collapse;
        width: 100%;
    }


    div.tableFixHead th, div.tableFixHead td {
        padding: 8px 16px;
    }

    div.tableFixHead th {
        line-height: 40px;
        background: #eee;
    }

tr.table-secondary {
    color: #9e9a9a !important;
}

tr.ocupado {
    color: #fe4040;
}

tr.celeste .led {
    fill: #0D9FCA
}

tr.rojo .led {
    fill: #d54848  
}

tr.verde .led {
    fill: #6fc748
}

tr.celeste .label.label-light-estado {
    color: #094e63 !important;
    background-color: #a5dceb !important;
}

tr.rojo .label.label-light-estado {
    color: #802525 !important;
    background-color: #e58383 !important;
}

tr.verde .label.label-light-estado {
    color: #37711e !important;
    background-color: #97d47c !important;
}

#kt_content {
    padding-bottom: 0px !important;
    padding-top: 25px !important;
}

.aside, .aside-menu{
    color: #FFF !important;
    background-color: #086094 !important;
}

.aside-menu .menu-nav > .menu-item > .menu-heading .menu-text, .aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
    color: #FFF !important;
}

span.svg-icon svg, span.menu-icon svg{
    fill: #FFF !important
}

.menu-text{
    color: #FFF !important
}

a.menu-link.active{
  background-color: #0D9FCA !important;
}

div.menu-submenu.show{
  display: block !important;
  overflow: hidden;
  max-height: 500px; 
  transition: max-height 0.2s ease-in !important;
}

div.menu-submenu.hide{
  display: block !important;
  max-height: 0px;
  overflow: hidden; 
  transition: max-height 0.2s ease-out !important;
}
